import { render, staticRenderFns } from "./workorder-small-type.vue?vue&type=template&id=33b78816&scoped=true&"
import script from "./workorder-small-type.vue?vue&type=script&lang=js&"
export * from "./workorder-small-type.vue?vue&type=script&lang=js&"
import style0 from "./workorder-small-type.vue?vue&type=style&index=0&lang=css&"
import style1 from "./workorder-small-type.vue?vue&type=style&index=1&id=33b78816&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33b78816",
  null
  
)

export default component.exports