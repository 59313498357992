<template>
  <div>
    <b-modal v-model="showModal" centered :title="title" size="m" title-class="font-18" hide-footer>
      <div class="flexList check-distri mb-3">
        <label style="width: 16%">大类：</label>
        <div class="col-sm-10 p-0 flexList">
          <yz-select :dict="dict.gddl" :bound="['sid', 'sortName']" class="w-100" v-model="form">
          </yz-select>
        </div>
      </div>
      <div class="flexList check-distri mb-3">
        <label style="width: 16%">小类名称：</label>
        <div class="col-sm-10 p-0 flexList">
          <input
              type="text"
              v-model="form.typeName"
              placeholder="小类名称"
              class="form-control w-100 h30"
          />
        </div>
      </div>
      <div class="flexList check-distri mb-3">
        <label style="width: 16%">受理人员：</label>
        <div class="col-sm-10 p-0 flexList">
          <div @click="$refs.chooseAdmin.show()" class="text-info">
            <div v-if="form.xm">{{form.xm}}</div>
            <div v-else>请选择受理人</div>
          </div>
        </div>
      </div>
      <div class="flexList check-distri mb-3">
        <label style="width: 16%">排序号：</label>
        <div class="col-sm-10 p-0 flexList">
          <input
              type="text"
              v-model="form.orderNum"
              placeholder="排序号"
              class="form-control w-100 h30"
          />
        </div>
      </div>
      <div class="mt-3 text-center">
        <button
            @click="commit"
            type="button"
            class="btn btn-info h30 w-md mr-3"
        >
          确定提交
        </button>
        <button
            type="button"
            @click="hide()"
            class="btn btn-secondary h30 w-md"

        >
          取消
        </button>
      </div>
    </b-modal>
    <chooseAdmin ref="chooseAdmin" @callback="choosePerson"></chooseAdmin>
  </div>
</template>

<script>
import {smallType} from "@/api/admin/workOrder/type";
import {addSmallType, updateSmallType} from "@/api/admin/workOrder/type";
import {getDicts} from "@/api/common/dict";
import YzSelect from "@/components/form/yzSelect.vue";
import chooseAdmin from "@/components/user/chooseAdmin";
export default {
  name: "addType",
  props: {
    title: {
      default: "工单小类",
    },
  },
  components:{
    YzSelect,
    chooseAdmin,
  },
  data() {
    return {
      showModal: false,
      form: {},
      dict: {}
    }
  },
  methods: {
    choosePerson(e){
      this.form.gh = e.yhzh
      this.form.xm = e.xm
      this.$forceUpdate()
    },
    commit(){
      if(this.form.tid){
        updateSmallType(this.form.tid, this.form).then(res=>{
          if(res.status){
            this.showModal = false
            this.$emit("success")
          }
        })
      } else {
        addSmallType(this.form).then(res=>{
          if (res.status){
            this.showModal = false
            this.$emit("success")
          }
        })
      }
    },
    show(id) {
      if (id){
        smallType(id).then(res=>{
          if(res.status){
            this.form = res.data
            this.showModal = true
          }
        })
      } else {
        this.form = {}
        this.showModal = true
      }
    },
    hide(){
      this.showModal = false
    }
  },
  mounted() {
    getDicts("gddl").then(res=> {
      this.dict = res
    })
  }
}
</script>

<style scoped>

</style>